import React, { useState, useEffect, useCallback } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";

import abiTheBeU from "./abi/thebeu.json";

import MintButton from "./components/MintButton";

const beu = "0x4E4A2AD4f7B63819128545A27Cabe6cf7DB68366";
//const baby = "0xE6C6f56Ffb0D88C88945cF2E07cbFF79081acb71";
const chain = "polygon";
const maxMinted = 30;

const App = () => {
    const { authenticate, enableWeb3, account, isAuthenticated, isInitialized, logout } = useMoralis();
    const Web3Api = useMoralisWeb3Api();

    const [load, setLoad] = useState(true)
    const [inputValue, setInputValue] = useState(1);
    const [getPrice, setPrice] = useState(0);
    const [getTotal, setTotal] = useState(0);
    const [getLimit, setLimit] = useState(0);
    const [getCurrent, setCurrent] = useState(0);
    const [userAddress, setEthAddress] = useState("");

    const loadPrice = useCallback(async () => {
        const getTokenPrice = await Web3Api.native.runContractFunction(
            {
                chain: chain,
                abi: abiTheBeU,
                address: beu,
                function_name: "checkMinPrice"
            }
        );
        await setPrice(getTokenPrice);
    }, [Web3Api]);

    const loadTotal = useCallback(async () => {
        const getTotalSupply = await Web3Api.native.runContractFunction(
            {
                chain: chain,
                abi: abiTheBeU,
                address: beu,
                function_name: "checkMaxTotalSupply"
            }
        );
        await setTotal(getTotalSupply);
    }, [Web3Api]);

    const loadCurrent = useCallback(async () => {
        const getSupply = await Web3Api.native.runContractFunction(
            {
                chain: chain,
                abi: abiTheBeU,
                address: beu,
                function_name: "totalSupply"
            }
        );
        await setCurrent(getSupply);
    }, [Web3Api]);

    const loadLimit = useCallback(async () => {
        const getLimitBeU = await Web3Api.native.runContractFunction(
            {
                chain: chain,
                abi: abiTheBeU,
                address: beu,
                function_name: "mintedBeU",
                params: {
                    address: userAddress
                }
            }
        );
        await setLimit(getLimitBeU);
    }, [Web3Api, userAddress]);

    const onChangeHandler = event => {
        setInputValue(event.target.value);
    };

    const unload = useCallback(() => {
        setLoad(false);
    }, [setLoad])

    useEffect(() => {if (isAuthenticated) {enableWeb3()}}, [enableWeb3, isAuthenticated]);
    useEffect(() => {if (account) {setEthAddress(account)}}, [account]);
    useEffect(() => {if (isInitialized && load === true) {
        loadPrice();
        loadTotal();
        loadCurrent();
        unload();
    }}, [
        load,
        loadPrice,
        loadTotal,
        loadCurrent,
        unload,
        isInitialized
    ]);
    useEffect(() => {if (isAuthenticated && userAddress !== "" && userAddress !== null && userAddress !== undefined) {
        loadLimit();
    }}, [
        loadLimit,
        userAddress,
        isAuthenticated
    ]);

    return (
        <div className="elementor-widget-container">
            <div className="mint-box">
                <img alt="BeU" loading="lazy" src="https://thebeu.io/wp-content/uploads/2022/03/animated-mint.gif" width="300" height="300" id="animated-mint" />
                <h6>
                    {getCurrent} / {getTotal} BeU Minted
                </h6>
                <p>
                    Each BeU will cost {getPrice / 1000000000000000000} MATIC
                    <br/>
                    Transaction limit = {isAuthenticated ? (maxMinted - getLimit) : maxMinted}
                </p>
                <div className="num-block">
                    <div className="num-in">
                        <span onClick={() => {if(inputValue === 1) {alert("You need to mint at least 1 BeU!")} else {setInputValue(inputValue - 1)}}} className="minus dis"></span>
                        <input type="text" className="in-num" onChange={onChangeHandler} value={inputValue} readOnly={true} />
                        <span onClick={() => {if(inputValue >= (maxMinted - getLimit)) {alert("You cannot mint more than " + (maxMinted - getLimit) + " BeU!")} else {setInputValue(inputValue + 1)}}} className="plus"></span>
                    </div>
                </div>

                {isAuthenticated ? (
                    <MintButton
                        beu={beu}
                        chain={chain}
                        input={inputValue}
                        price={getPrice}
                    />
                ) : (
                    <button onClick={authenticate}>CONNECT WALLET</button>
                )}

                {isAuthenticated ? (
                    <button onClick={logout}>DISCONNECT WALLET</button>
                ) : ""}
                
            </div>
        </div>
    );
};

export default App;
