import React, { useState, useEffect } from "react";
import { useWeb3ExecuteFunction } from "react-moralis";

import abiTheBeU from "../abi/thebeu.json";

const MintButton = ({beu, chain, input, price}) => {
    
    const { fetch } = useWeb3ExecuteFunction();

    const [repeat, setRepeat] = useState("");
    const total = price * input;

    useEffect(() => {if (input !== "" && input != null) {setRepeat(input)}}, [input]);

    const options = {
        chainName: chain,
        abi: abiTheBeU,
        contractAddress: beu,
        functionName: "batchMintTheBeU",
        msgValue: total,
        params: {
            _batchSize: repeat
        },
    }

    const mint = async () => {
        const tx = await fetch({ 
            params: options,
            onSuccess: () => alert("Minting Completed"),
        });
        await tx.wait();
        
    };

    return (
        <button onClick={() => mint()}>MINT NOW</button>
    );

};

export default MintButton;
